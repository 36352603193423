export type BufferUpdate = {
    n: string, // Node ID
    type: UpdateType.PROP | UpdateType.ATTR, // 
    k: string,
    v: string | number
}

export type BufferObject = {
    [nodeId: string]: {
        [type in UpdateType]: {
            [key: string]: Array<string | number>
        }
    }
}

export enum UpdateType {
    'PROP',
    'ATTR'
}