
import { ika } from '../ika'
import { UpdateType } from '../types/buffer';
import { BindingEvent } from '../types/events'
import BindingComponent from '../core/bind';

export default class IkaB extends BindingComponent {
    constructor() {
        super();
    }

    bindValueChanged(update: BindingEvent.ValueUpdate) {
        ika.buffer.push({
            n: this.getNodeId(),
            type: UpdateType.PROP,
            v: update.v,
            k: this.hasAttribute('allow-html') ? 'innerHTML' : 'textContent'
        })
    }
}
