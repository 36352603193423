performance.mark('ika-init')

import { IkaBuffer, processBuffer } from './core/buf'
import IkaSDC from './components/sdc';
import { InitBundle } from './core/init';
import IkaRegistry from './core/reg';
import { ComponentCall, CustomEventTypes } from './types'
import { IkaA, IkaAB, IkaB, IkaBF, IkaImport, IkaME, IkaRoute } from './components';
import IkaDebug, { printDebugMessage } from './debug';
import { DebugFilter, IkaStatus } from './types/debug';
import { ResourceManager } from './core/res';

export * from './types'
export { getParentIkaComponent } from './core/bind'
export { IkaComponent, IkaComponentOptions, defineIkaComponent } from './components/IkaComponent';
export { ResourceManager } from './core/res'

export { BindingDictionary, TransformationFunction } from './components/me/types'

type IkaConfig = {
    UPS?: number,
    verbose?: boolean,
    suppressMessages?: Array<DebugFilter>,
    filterMessages?: Array<DebugFilter>,
    debugMode?: 'each' | 'bar',
    locale?: 'en-gb',
    unprotected?: boolean
}

declare global {
    // const IKA_UPS: number
    const IKA_CONFIG: IkaConfig
    interface Window {
        IKA_CONFIG: IkaConfig
        IKA_ROUTE_CONFIG
    }
}

export const IKA_VERSION = '0.2.0'
export const ika = {
    debug: IkaDebug,
    print: printDebugMessage,
    buffer: new IkaBuffer(),
    reg: new IkaRegistry(),
    data: new InitBundle(),
    ResourceManager: new ResourceManager(),
}

initIka()

function initIka() {
    validateIkaConfig()

    ika.print(IkaStatus.Initialising, IKA_VERSION)

    boundaryMonitoring()
    importIkaComponents()
    startBufferProcessing()

    performance.measure('ika-init-complete', 'ika-init')

    function validateIkaConfig() {
        const IKA_DEFAULT_CONFIG: IkaConfig = {
            UPS: 60,
            locale: 'en-gb'
        }

        window.IKA_CONFIG ??= {}
        window.IKA_ROUTE_CONFIG ??= { rules: [] }

        Object.entries(IKA_DEFAULT_CONFIG).forEach(([key, value]) => {
            window.IKA_CONFIG[key] ??= value
        })
        if (!window.IKA_CONFIG.unprotected) { Object.freeze(window.IKA_CONFIG) }
    }

    function importIkaComponents() {
        customElements.define('ika-a', IkaA)
        customElements.define('ika-b', IkaB)
        customElements.define('ika-bf', IkaBF)
        customElements.define('ika-me', IkaME)
        customElements.define('ika-import', IkaImport)
        customElements.define('ika-ab', IkaAB)
        customElements.define('ika-sdc', IkaSDC)
        customElements.define('ika-route', IkaRoute)
    }

    function startBufferProcessing() {
        document.addEventListener('DOMContentLoaded', (e) => {
            setInterval(processBuffer, 1000 / IKA_CONFIG.UPS);
        })
        ika.print(IkaStatus.BufferStart)
    }
    function boundaryMonitoring() {
        // const MAX_RETRIES = 5 as const

        document.addEventListener(CustomEventTypes.ComponentCall, (e: CustomEvent<ComponentCall.EventDetails>) => {
            console.warn(`Ika event was not caught and has reached document boundary.`);
            console.log(e)
        })
    }
}

export function elementIsIkaComponent(e: Element) {
    return typeof Object.getPrototypeOf(e)['getIkaVersion'] == 'function' && Object.getPrototypeOf(e)['getIkaVersion']() == IKA_VERSION
}