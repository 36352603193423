import { QueueLock } from "../common"
import IkaMapElements from "../components/me"
import IkaRoute from "../components/route"
import { ika, IkaComponent } from "../ika"
import { BindCallback, BindCallbackArray } from "../types/binds"
import { NodeEvent, RouterEvent } from "../types/debug"
import BindingComponent from "./bind"
import StateBindRegister from "./sbr"

type ImportRegistry = {
    [p: string]: {
        t: Node[], // Template or script (in head) nodes
        n: Node[]  // Other nodes
    }
}

export type BindRecords = {
    [bind: string]: BindRecord
}
export type BindRecord = {
    v: any,
    cb: BindCallbackArray
    p?: GlobalBindPermissions
}

export type GlobalBindPermissions = {
    rw: Array<string>,
    r?: Array<string>
}

export default class IkaRegistry {
    #imports: ImportRegistry = {}
    #importedComponents: { [templateId: string]: string } = {}
    #nodeIds: { [id: string]: Node } = {}
    #binds = new StateBindRegister(this as any);
    #queue = new QueueLock()
    #awaitingIds: Array<Element> = []
    #routes: { [key: string]: { n: IkaRoute, p: string } } = {}
    #templateRequests: { [key: string]: Array<(() => void)> } = {}
    #anchorListenerAborter: AbortController

    constructor() { }

    debugPrint() {
        if (IKA_CONFIG.debugMode) {
            console.log(this.#binds.listStates());
        }
    }

    getPathMD5(path: string) {
        return this.#importedComponents[path]
    }

    async registerComponentImport(component: string, templateText: string) {
        this.#importedComponents[component] = await getSHA1Hash(templateText)
    }

    // deregisterComponentImport(component: string, template: string) {
    //     delete this.#importedComponents[component]
    // }

    hasImport(href: string) {
        return href in this.#imports
    }
    registerImport(href: string) {
        this.#imports[href] = { t: [], n: [] }
    }
    addTemplateNode(node: Node, templateId: string, href: string) {
        this.#imports[href].t.push(node)
        this.#templateRequests[templateId]?.forEach(r => r())
    }
    addBodyNodes(href: string, nodes: Array<Node> | NodeList) {
        this.#imports[href].n.push(...Array.from(nodes).filter(n => n.nodeType != Node.TEXT_NODE))
    }
    addScriptNodes(href: string, nodes: Array<Node> | NodeList) {
        this.#imports[href].t.push(...Array.from(nodes).filter(n => n.nodeType != Node.TEXT_NODE))
    }
    getImportRegisterByPath(p: string) {
        return this.#imports[p]
    }

    async getComponentTemplate(id: string) {
        // change this part when the registered imports are under component names
        let template = getTemplate(id)
        if (template) { return template }

        this.#templateRequests[id] ??= []
        let resolver;
        const templateInserted = new Promise(r => resolver = r)
        this.#templateRequests[id].push(resolver)
        await templateInserted

        return getTemplate(id)

        function getTemplate(id: string) {
            let template = document.querySelector(`#${id}`)
            if (template) {
                if (template instanceof HTMLTemplateElement) {
                    return template
                } else {
                    throw new Error(`Template with ID "${id}" is not a template element.`)
                }
            }
        }
    }


    // Pass StateBindRegisters class methods available through this.#binds as IkaRegistry class methods
    subscribeToBind(k: string, nodeId: string, cb: BindCallback) { this.#binds.subscribeToBind(k, nodeId, cb) }
    unsubscribeToBind(nodeId: string, k: string) { this.#binds.unsubscribeToBind(nodeId, k) }
    setBindValue(k: string, v: any, nodeId?: string, p?: GlobalBindPermissions) {
        this.#binds.setValue(k, v, nodeId, p)
    }
    getBindValue(k: string, nodeId?: string) {
        const v = this.#binds.getValue(k, nodeId)
        return v
    }

    registerNode(requester: BindingComponent, n: Element, cb: (id: string, n: Element, r: BindingComponent) => void) {
        this.#queue.queue().then(() => {
            // This checks if a node has already been registered. Not entirely necessary to normal functioning?
            if (!requester || !n) { console.error(`Could not register node - requester and element must be supplied.`) }

            if (!shouldRegisterNode.bind(this)(n)) {
                this.#queue.next()
                return;
            }

            this.getNewIdThenInvokeCallback(n, cb, requester)
            this.#awaitingIds.push(n)
            this.#queue.next()
        })

        function shouldRegisterNode(this: IkaRegistry, n: Element) {
            const hasId = n instanceof HTMLElement && (n instanceof IkaComponent || n instanceof IkaMapElements) && n.getNodeId()
            if (hasId) return

            const existingRegistration = Object.entries(this.#nodeIds).find(([id, node]) => node.isSameNode(n))
            if (existingRegistration) {
                ika.print(NodeEvent.AlreadyRegistered, existingRegistration[0], n)
                cb(existingRegistration[0], n, requester)
                return;
            }

            const isAwaitingId = this.#awaitingIds.find(node => node.isSameNode(n))
            if (isAwaitingId) return

            return true
        }
    }

    getNodeFromId(id: string) {
        if (!(id in this.#nodeIds)) {
            console.warn(`A query is made for node id %c${id} %c, and the ID was not registered.`, 'color: #999', 'color: unset')
        }
        return this.#nodeIds[id]
    }

    deregisterNode(n: Element, id: string) {
        if (!(this.#nodeIds[id])) {
            console.warn(`Trying to deregister node ID %c${id}%c, but the ID was not registered.`, 'color: #999', 'color: unset')
        } else if (!this.#nodeIds[id].isSameNode(n)) {
            console.warn(`Trying to deregister node ID %c${id}%c, but the supplied node was not the same as records.`, 'color: #999', 'color: unset')
        } else {
            delete this.#nodeIds[id]
            this.#binds.clearDeregisteredNode(id)
            ika.print(NodeEvent.Deregistered, n.tagName.toLowerCase(), id)
        }
    }

    getRegistry() {
        if (IKA_CONFIG.debugMode) {
            return this.#nodeIds
        }
    }

    async getNewIdThenInvokeCallback(n: Element, cb: (id: string, n: Element, r: BindingComponent) => void, r) {
        let newId;
        do {
            newId = await generateIdString()
        } while (newId in this.#nodeIds)

        this.#nodeIds[newId] = n
        this.#awaitingIds.splice(this.#awaitingIds.findIndex(node => node.isSameNode(n)), 1)
        // Object.freeze(this.#nodeIds[newId])

        ika.print(NodeEvent.Registered, n, newId)
        cb(newId, n, r)
    }

    registerRouter(key: string, ref: IkaRoute) {
        const init = Object.keys(this.#routes).length == 0

        if (key in this.#routes) {
            ika.print(RouterEvent.OverwritingKey, key, ref)
        }
        this.#routes[key] = { n: ref, p: null }

        if (init) {
            if (!window.IKA_ROUTE_CONFIG.unprotected) { Object.freeze(window.IKA_ROUTE_CONFIG) }
            routeCurrentLocation()
            window.addEventListener('popstate', routeCurrentLocation)
        }
    }
    unregisterRouter(key: string) {
        delete this.#routes[key]
        Object.keys(this.#routes).length == 0 && this.unsetAnchorListener();
    }

    async setRoute(key: string, url: string, state?, options?: SetRouteOptions) {
        // When using setRoute() of the Registry, it is the URL users sees in browser
        // The URL passed to this method is parsed against the rules in IKA_ROUTE_CONFIG
        // State can also be set in the history object, because pushState() or replaceState is called here.
        // The setRoute() method of a <ika-route> component simply puts the URL into a <ika-import> element
        // It does not interact with the history object / page state.

        if (!(key in this.#routes)) {
            console.warn(`Trying to set route "${key}" but it was not registered.`)
            return
        }
        const target = this.#routes[key]

        // Garbage collection for old routes cleared when requesting
        if (!target.n.isConnected) { delete this.#routes[key]; return }
        const u = new URL(url, window.location.href)
        const [res, ruleState] = await getResourcePathAndState(u.pathname, u.searchParams, u.hash)

        if (!(target.p == res && !options?.force)) {
            target.n.setRoute(res)
            target.p = res
        }

        history[options?.replaceState ? 'replaceState' : 'pushState'](
            state ?? ruleState,
            null,
            options?.setPath ?? url)

        window.dispatchEvent(new CustomEvent('ika:popstate', { detail: { state: state ?? ruleState } }))
    }
    getRoute(key: string) { return this.#routes[key].p }

    setAnchorListener() {
        if (this.#anchorListenerAborter) return

        this.#anchorListenerAborter = new AbortController()
        document.addEventListener('click', (e: Event) => {
            const src = e.composedPath()[0]
            if (src instanceof HTMLAnchorElement) {
                const routeTarget = src.dataset['ikaTarget']
                if (!routeTarget) return

                e.preventDefault()
                const href = src.getAttribute('href')
                this.setRoute(routeTarget, href)
            }
        }, { signal: this.#anchorListenerAborter.signal })
    }
    unsetAnchorListener() { this.#anchorListenerAborter?.abort() }
}

type SetRouteOptions = Partial<{
    force: boolean,
    replaceState: boolean,
    noResourceDirPrefix: boolean,
    setPath: string,
}>

async function generateIdString() {
    const threads = [], parts = 4, length = 6
    for (let i = 0; i < 4; i++) {
        threads.push(new Promise((res) => {
            let str = ''
            for (let j = 0; j < length; j++) {
                const base = Math.floor(Math.random() * 26) + 65
                const bump = 32 * Math.round(Math.random())
                str += String.fromCharCode(base + bump)
            }
            res(str)
        }))
    }
    const res = await Promise.all(threads)
    return res.join('-')
}

async function routeCurrentLocation() {
    const path = `${window.location.pathname}${window.location.search}${window.location.hash}`
    ika.reg.setRoute(window.IKA_ROUTE_CONFIG.rootKey, path, null, {
        replaceState: true
    })
}

async function getResourcePathAndState(path: string, params?: URLSearchParams, anchor?: string) {
    // To add: rules based on more than params and anchor
    const config = window.IKA_ROUTE_CONFIG
    const rules: Array<IkaRouteRule> = config.rules

    const matchedRule = rules.find(r => {
        if (typeof r.path == 'string') {
            return r.path == path
        } else if (r.path instanceof RegExp) {
            return r.path.test(path)
        }
    })
    const res = matchedRule ? matchedRule.res : `${config.resourceDir ?? ''}${path}`
    const state = matchedRule ? await getStateFromRule(matchedRule.state, path) : null

    return [res, state]
}

async function getStateFromRule(stateFunction: (path: string) => any, path: string) {
    return typeof stateFunction == 'function'
        ? await stateFunction(path)
        : stateFunction
}

type IkaRouteRule = {
    path: string | RegExp,
    res: string,
    state?
}

async function getSHA1Hash(s: string): Promise<string> {
    const encoded = await crypto.subtle.digest('SHA-1', new TextEncoder().encode(s))
    return Array.from(new Uint8Array(encoded)).map((b) => b.toString(16).padStart(2, '0')).join('')
}