import { IkaDebugStyles } from "../../debug"
import { ika } from "../../ika"
import { IkaComponentOptions, IkaComponent } from "./ikaComponent"

export function defineIkaComponent(componentName: string, options?: IkaComponentOptions) {
    try {
        customElements.define(componentName, class extends IkaComponent {
            constructor() {
                super(options)
            }
        })
    } catch (e) {
        if (e.code == 9) { // DOMException: NotSupportedError
            ika.debug(`Element %c${componentName}%c is already defined. Now potentially using a new template.`,
                [IkaDebugStyles.ComponentName, 'color: unset'])
        } else { throw e }
    }
}