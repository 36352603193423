import { ika } from '../ika'

export default class IkaImport extends HTMLElement {
    #url: URL;
    
    constructor() {
        super();
        moveSelfToHead(this)
        const url = new URL(this.getAttribute('src'), window.location.href.match(/.+\//)[0])

        if (ika.reg.hasImport(url.href)) return;
        this.#url = url;

        ika.reg.registerImport(url.href)
        this.#processResponse(fetch(url))
    }

    async #processResponse(p: Promise<Response>) {
        const r = await p
        if (!r.ok) {
            setError(`Status ${r.status}: Could not load resource from ${r.url}`);
            return;
        }

        const contentMIME = r.headers.get('content-type').match(/[^;]+/)[0]
        switch (contentMIME) {
            case 'text/html':
                this.#processHTMLImport(await r.text()); break;
            default:
                setError(`Unsupported MIME type "${contentMIME}" for "${r.url}"`)
        }

        function setError(msg: string) {
            console.error(msg)
        }
    }
    async #processHTMLImport(content: string) {
        const htmlParser = new DOMParser()
        const doc = htmlParser.parseFromString(content, 'text/html')

        moveIkaTemplates(doc, this.#url.href)
        reinsertScripts(doc)
        // If the component further imports other components, insert those <ika-import> elements into the DOM
        doc.querySelectorAll('ika-import').forEach(e =>
            !document.querySelector(`ika-import[src="${e.getAttribute('src')}"]`) && document.body.appendChild(e)
        )


        // Execute scripts
        // const scripts = await Promise.all(Array.from(doc.scripts).map(
        //     // Either fetch a script resource or take the script element's contents
        //     async n => n.src
        //         ? await (await fetch(n.src)).text()
        //         : n.textContent
        // ));
        // scripts.forEach(s => {
        //     try { Function(`"use strict"; ${s}`)() }
        //     catch (e) { console.log(e) }
        // })
    }
}

function moveSelfToHead(e: Element) {
    let importsContainer = document.getElementsByTagName('ika:imports')[0]
    if (!importsContainer) {
        importsContainer = document.createElement('ika:imports')
        document.head.append(importsContainer)
    }
    importsContainer.appendChild(e)
}

export function reinsertScripts(doc: Document): Array<HTMLScriptElement> {
    return Array.from(doc.scripts).map(s => {
        const newScript = document.createElement('script')
        newScript.text = s.text
        Array.from(s.attributes).forEach(attr => newScript.attributes.setNamedItem(attr.cloneNode() as Attr))
        document.head.appendChild(newScript)
        s.remove()

        return newScript
    })
}
export function moveIkaTemplates(doc: Document, sourceUrl: string, noTemplates?: () => void) {
    const getContainer = (d: Document) => d.getElementsByTagName('ika:templates')[0]
    const templates = getContainer(doc)?.querySelectorAll('template')
    if (templates) {
        let container = getContainer(document)
        if (!container) {
            container = document.createElement('ika:templates')
            document.head.append(container)
        }
        // TO DO: Add template name collision detection
        templates.forEach(t => {
            container.appendChild(t);
            ika.reg.addTemplateNode(t, t.id, sourceUrl);
        })
    } else {
        noTemplates?.()
    }
}