import { IkaComponent } from "../ika";
export { printDebugMessage } from "./messages";

export default function (s: string, css?: Array<string>, objects?: Array<any>) {
    if (!IKA_CONFIG.verbose) return;

    const formatting = ['color: #6aaff8; font-weight: 600', 'color: unset; font-weight: unset']
    const args = [...formatting]
    for (const match of s.matchAll(/(%[co])/g)) {
        const target = match[0] == '%c' ? css : objects
        args.push(target[0]);
        target.shift()
    }

    console.debug(`%c‹debug› %c` + s, ...args)
}

export enum IkaDebugStyles {
    ComponentName = 'color: #60d38e',
    VariableValue = 'color: #ab49f5',
    NodeID = 'color: #999',
    Unset = 'color: unset'
}

export function attachDebugButton(ref: IkaComponent) {
    const debugBox = document.createElement('div')
    debugBox.setAttribute('style', 'display: var(--global-debug-button-display)')
    const btn = document.createElement('button');
    btn.innerHTML = ref.tagName.toLowerCase()
    btn.setAttribute('style',
        `margin: 0.5rem 0; background: #666; color: #eee; padding: 0.25rem; border: 1px solid #222; cursor: pointer;`)
    btn.onclick = (e) => { e.stopPropagation(); ref.debugPrint() }
    debugBox.append(btn);
    (ref.shadowRoot ?? ref).append(debugBox)
}