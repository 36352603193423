import IkaMapElements from "."
import { DocumentCreateElement } from "../../common/shortcuts"
import { applyBindingDictionary } from "./applyBindingDictionary"
import { BindingDictionaries, BindingDictionary } from "./types"

export enum InsertionOption {
    Before,
    Child
}

export function mapChildren(this: IkaMapElements, cn: string,
    bd: BindingDictionaries,
    bdKey: string,
    mapArray: Array<any>,
    subKey: Array<Element> | null,
    insertionMethod: InsertionOption,
    insertionTarget: Node): Array<Node> {

    const fragment = new DocumentFragment()
    mapArray.forEach((data, i) => {
        const newElement = DocumentCreateElement(cn)
        applyBindingDictionary(newElement, bd[bdKey], data, i)
        fragment.appendChild(newElement)
    })


    // Recursively insert nodes into mapped nodes if <ika:subkey> is used
    if (Array.isArray(subKey)) {
        subKey.forEach((subKeyElement) => {
            const [mfKey, cnKey, subBdKey] = ['mf', 'cn', 'bd'].map(key => subKeyElement.getAttribute(key))
            fragment.childNodes.forEach((node, i) =>
                mapChildren.bind(this)(cnKey, bd, subBdKey, mapArray[i][mfKey], getSubKeyArray(subKeyElement), InsertionOption.Child, node)
            )
        })
    }

    const insertedNodes = Array.from(fragment.childNodes)
    const target = insertionTarget ?? this
    insertionMethod == InsertionOption.Before
        ? target.parentNode.insertBefore(fragment, target)
        : target.appendChild(fragment)

    return insertedNodes
}

export function getSubKeyArray(target: Element): Array<Element> | null {
    return Array.from(target.children).filter(e => e.tagName.toLowerCase() == 'ika:subkey')
}