import { ika } from '../../ika'
import { BindingEvent } from '../../types/events';
import { MapElementEvent } from '../../types/debug';
import { IkaMeBindingValue } from './types';
import { postMappingOperations } from './postMappingOperations';
import { validateBd } from './validateBd';
import { mapChildren, getSubKeyArray, InsertionOption } from './mapChildren';
import { setBind } from './setBind';

export enum PostMappingOption {
    Aside = 'aside',
    Remove = 'remove'
}

const observedAttributes = ['mf', 'ika:is', 'cn']
export default class IkaMapElements extends HTMLElement {
    #nodeId: string = null
    #hasRegisteredBind: boolean = false;
    #mappedNodesInDOM: Array<Node> = []
    #insertionRoot: { marker: Node, method: InsertionOption };

    constructor() {
        super();
        this.#insertionRoot = {
            marker: this as Node,
            method: [PostMappingOption.Aside, PostMappingOption.Remove].includes(this.getAttribute('post') as PostMappingOption)
                ? InsertionOption.Before
                : InsertionOption.Child
        }
    }

    // Custom element lifecycle method to observe changed attributes
    static get observedAttributes() { return observedAttributes }
    attributeChangedCallback(attrName: string, oldVal: string, newVal: string) {
        const [mf, is, cn] = observedAttributes.map(key => this.getAttribute(key))
        if (!mf || !cn || !is) return;

        if (!this.#nodeId) {
            // Node ID is not set by a parent component - binding to be made after ID is obtained.
            ika.reg.registerNode(this as any, this, (id: string, n, r) => {
                r.setNodeId(id)
                setBind.bind(this)(is)
                this.#hasRegisteredBind = true
            })
        } else if (attrName == 'ika:is' && oldVal != newVal) {
            // "ika:is" attribute changed. Register new binding and deregister old binding.
            setBind.bind(this)(oldVal, true)
            setBind.bind(this)(newVal)
        } else {
            // Node ID could have been set by a parent component (e.g. <ika-ab>), but the binding has not been registered yet.
            if (!this.#hasRegisteredBind) {
                setBind.bind(this)(is)
                this.#hasRegisteredBind = true
            } else {
                this.bindValueChanged({
                    k: `ika-me:${is}`,
                    v: ika.reg.getBindValue(`ika-me:${is}`, this.#nodeId)
                })
            }
        }
    }

    bindValueChanged(update: BindingEvent.ValueUpdate<IkaMeBindingValue>) {
        const marker = this.#insertionRoot.marker
        if (!marker.isConnected) { throw 'Insertion marker no longer connected' }

        const mf = this.getAttribute('mf')
        const bdKey = this.getAttribute('bd') ?? '_default'
        const bd = update.v?.bd

        if (!update.v || (bd?.[bdKey] && !validateBd(bd[bdKey]))) { return }

        const hasMFValue = update.v?.mf && mf in update.v.mf && update.v.mf[mf] != null
        !hasMFValue && ika.print(MapElementEvent.NoMapFrom, mf)

        const mapArray = getMapArray(update.v.mf?.[mf])
        function getMapArray(mf: Array<any> | Set<any>) {
            if (!mf) return null
            return mf instanceof Set ? [...mf.values()] : mf
        }

        if (!Array.isArray(mapArray)) { ika.print(MapElementEvent.UnexpectedMFType, mapArray); return; }
        if (mapArray == null) { return }

        const mappedNodes = mapChildren.bind(this)(
            this.getAttribute('cn'),
            bd, bdKey, mapArray, getSubKeyArray(this),
            this.#insertionRoot.method, marker
        )

        const postMappingMode = this.getAttribute('post') as PostMappingOption
        if (mappedNodes.length == 0 && postMappingMode == PostMappingOption.Aside) {
            marker.parentNode.insertBefore(this, marker)
        } else {
            postMappingOperations.bind(this)(postMappingMode)
        }

        // Delete previously mapped nodes and update the list for record keeping
        this.#mappedNodesInDOM.forEach(n => n.parentNode?.removeChild(n))
        this.#mappedNodesInDOM = mappedNodes

        // Update insertion marker after new mapping
        if (postMappingMode == PostMappingOption.Aside) {
            this.#insertionRoot.marker = this.#mappedNodesInDOM[0] ?? this
        }
    }

    onParentComponentLoaded() { }

    setNodeId(id: string) { this.#nodeId = id }
    getNodeId() { return this.#nodeId }
}