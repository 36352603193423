export class QueueLock {
    #queue: Array<() => void>;
    constructor() { this.#queue = [] }

    queue() {
        const queuePromise: Promise<void> = new Promise((resolve, reject) => {
            this.#queue.push(resolve)
            this.#queue.length == 1 && resolve()
        });

        return queuePromise
    }

    next() {
        this.#queue.shift()
        this.#queue[0] && this.#queue[0]()
    }
}