// <ika-route> is exempt from the Buffer system,
// as requests for new content shouldn't be so many that it causes performance problems, 
// and fetch requests should start straight away.

import { IkaStatic } from "../core/res";
import { ika, ResourceManager } from "../ika";
import { ImportEvent } from "../types/debug";
import { moveIkaTemplates, reinsertScripts } from "./import";

export default class IkaRoute extends HTMLElement {
    constructor() {
        super();
        ika.reg.setAnchorListener();
        ika.reg.registerRouter(this.getAttribute('key'), this)

        const initRoute = this.getAttribute('src')
        initRoute && this.setRoute(initRoute)
    }

    async setRoute(path: string) {
        this.replaceChildren()
        const r = await fetch(path)
        if (!r.ok) {
            this.innerHTML = `<span>🚩Failed to load from ${path}</span>`
            return;
        }
        ika.reg.registerImport(r.url)
        const parser = new DOMParser()
        const doc = parser.parseFromString(await r.text(), 'text/html')

        processStatic(doc)
        const scripts = reinsertScripts(doc)
        moveIkaTemplates(doc, r.url)

        Array.from(doc.body.getElementsByTagName('ika:templates')).forEach(n => n.remove())
        doc.body.querySelectorAll('link').forEach(n => doc.head.appendChild(n))

        const [bodyNodes, headNodes] = [[...doc.body.childNodes], [...doc.head.childNodes, ...scripts]]

        // Register imports - when all body nodes are removed from DOM, the script nodes are removed
        // Currently not linked with re-routes, since routes can be located inside another route.
        // Note this means you must have body nodes that are not text nodes
        // Text nodes are not registered because these could be new line characters
        ika.reg.addBodyNodes(r.url, bodyNodes)
        ika.reg.addScriptNodes(r.url, headNodes)
        const registryEntry = ika.reg.getImportRegisterByPath(r.url)
        const observer = new MutationObserver((m, o) => processMut(r.url, registryEntry, m, o))
        observer.observe(this, { childList: true })

        ika.print(ImportEvent.ImportedNodes, path, registryEntry)

        this.append(...bodyNodes)
        this.ownerDocument.head.append(...headNodes)

        // By default, when a new route is applied, <ika-route> will scroll itself into view from the top.
        if (!this.hasAttribute('no-scroll-on-route')) {
            // Check if top of the element is visible
            const topOfElementFromTopOfViewport = this.getBoundingClientRect().top
            if (topOfElementFromTopOfViewport >= 0 && topOfElementFromTopOfViewport < window.innerHeight) return

            (document.querySelector(this.dataset.scrollToMark) ?? this).scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
}

function processStatic(doc: Document) {
    const staticData = Array.from(doc.querySelectorAll('script[ika-static]'))
    staticData.forEach((e: HTMLScriptElement) => {
        try {
            const d: IkaStatic = JSON.parse(e.textContent)
            if (!d.headers || !d.data) { throw 'Invalid static data' }

            const resMan = new ResourceManager()
            resMan.cache(d.url, d.headers, d.data, d.options, true)
            e.remove()
        } catch (e) {
            throw e
        }
    })
}

function processMut(url: string, entry: { t: Array<Node>, n: Array<Node> }, mutList: MutationRecord[], observer: MutationObserver) {
    const removeMuts = mutList.filter(m => m.removedNodes.length > 0)
    if (removeMuts.length == 0) return

    for (const mut of removeMuts) {
        mut.removedNodes.forEach(n => {
            const i = entry.n.findIndex(m => m.isSameNode(n))
            i != -1 && entry.n.splice(i, 1)
        })
    }
    ika.print(ImportEvent.RemovedNode, url, removeMuts, entry)
    if (entry.n.length == 0) {
        entry.t.forEach(templateOrScriptNode => {
            templateOrScriptNode.parentNode.removeChild(templateOrScriptNode)
        })
        entry.t = []
        observer.disconnect()
    }
}