import { CustomEventTypes, ika } from "../../ika";
import { processComponentCall } from "./processEvent";
import { ComponentEvent } from "../../types/debug";
import { IkaComponent } from "./ikaComponent";

export function addEventListeners(this: IkaComponent, eventBinds: { [eventType: string]: (e: Event | CustomEvent) => void; }) {
    if (!eventBinds) { eventBinds = {}; };
    eventBinds[CustomEventTypes.ComponentCall] = processComponentCall;

    Object.entries(eventBinds).forEach(([eventType, handler]) => {
        this.addEventListener(eventType, (e) => eventHandlerWrapper.bind(this)(e, handler));
    });

    function eventHandlerWrapper(this: IkaComponent, e: Event | CustomEvent, handler: (e: Event | CustomEvent) => void) {
        const eventSource = e.composedPath()[0];

        if (!(eventSource instanceof HTMLElement)) {
            ika.print(ComponentEvent.ReceivedEventNotFromElement, eventSource); return;
        } else {
            ika.print(ComponentEvent.ReceivedEvent, this.tagName.toLowerCase(), e);
        }

        handler.bind(this)(e);
    }
}
