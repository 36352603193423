import { ComponentCall, CustomEventTypes } from "../types/events";
import { IkaComponent } from "./IkaComponent";

export default class IkaBindToFunction extends HTMLElement {
    constructor() {
        super();
        Object.keys(this.dataset).forEach(eventType => {
            processDataAttribute.bind(this)(`data-${eventType}`)
        })
        new MutationObserver(handleAttributeChange.bind(this)).observe(this, {
            attributes: true,
            attributeOldValue: true
        })
    }
}

function handleAttributeChange(this: IkaComponent, records: Array<MutationRecord>) {
    records.forEach(record => {
        if (record.type != 'attributes') return
        processDataAttribute.bind(this)(record.attributeName)
    })
}

function processDataAttribute(this: IkaComponent, name: string) {
    if (!name.startsWith('data-')) return
    const eventType = name.slice(5)
    if (!eventType) return

    this.addEventListener(eventType, (e) => {
        handleEvent.bind(this)(e, this.getAttribute(name))
    })
}

function handleEvent(this: IkaComponent, e: Event, bind: string) {
    if (this.hasAttribute('preventDefault')) {
        e.cancelable
            ? e.preventDefault()
            : console.warn(`<ika-bf> was instructed to cancel an uncancelabe event of type "${e.type}".`)
    }
    sendEventToComponent.bind(this)(e, bind)
}

function sendEventToComponent(this: IkaBindToFunction, e: Event, bind: string) {
    const eventDetails: ComponentCall.EventDetail.InvokeFunction = {
        type: ComponentCall.EventType.InvokeFunction,
        payload: {
            invocationEvent: e,
            key: bind
        }
    }
    const leap = Number.parseInt(this.getAttribute('leap'))
    if (Number.isInteger(leap)) { eventDetails.payload.leap = leap }

    const componentCall = new CustomEvent(CustomEventTypes.ComponentCall, {
        detail: eventDetails,
        bubbles: true,
        cancelable: true,
        composed: true
    })

    this.dispatchEvent(componentCall)
}