import { ika } from "../../ika";
import { MapElementEvent } from "../../types/debug";
import { BindingDictionary } from "./types";

export function validateBd(bdEntry: BindingDictionary) {
    if (bdEntry.states && typeof bdEntry.states != 'object') {
        ika.print(MapElementEvent.UnexpectedBDStateObject);
        return;
    }

    const unexpectedBdValue = [bdEntry.attr, bdEntry.prop, ...Object.values(bdEntry.states ?? {})].find(v => typeof v != 'function')
    if (unexpectedBdValue) {
        ika.print(MapElementEvent.UnexpectedBDType, unexpectedBdValue);
        return;
    }

    return true;
}