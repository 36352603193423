import { IkaDebugStyles } from ".."
import { getEventSourceAndTagName } from "../../common"
import IkaAttributeBind from "../../components/ab"
import IkaMapElements from "../../components/me"
import { BindingDictionary } from "../../components/me/types"
import { NodeEvent, BindEvent, ComponentEvent, IkaStatus, UnexpectedError, ImportEvent, MapElementEvent, BufferEvent, RouterEvent, ResourceManagerEvent } from "../../types/debug"
import { buildIndex } from "../messages"
const INDENT = `        `

const en_gb = buildIndex({
    [NodeEvent.Registered](node: Element, id: string) {
        return {
            s: `Registered node %c<${node.tagName.toLowerCase()}>%c with ID %c${id}%c\n` +
                `${INDENT}Element: %o`,
            o: [node],
            css: [IkaDebugStyles.ComponentName, 'color: unset', IkaDebugStyles.NodeID, IkaDebugStyles.Unset]
        }
    },
    [NodeEvent.Deregistered](tagName: string, id: string) {
        return {
            s: `Node %c<${tagName}>%c with ID %c${id}%c is deregistered`,
            css: [IkaDebugStyles.ComponentName, 'color: unset', IkaDebugStyles.NodeID, 'color: unset']
        }
    },
    [NodeEvent.AlreadyRegistered](nodeId: string, node: Node) {
        return {
            s: `Another registration request is made for a node. It's currently registered as %c${nodeId}\n%o`,
            css: [IkaDebugStyles.NodeID],
            o: [node],
            mode: 'warn'
        }
    },
    [NodeEvent.NodeAlreadyHasId](id: string, element: Element) {
        return {
            s: `Node id already set to %c${id}%c on component %c<${element.tagName.toLowerCase()}>\n%o`,
            o: [element],
            css: [IkaDebugStyles.NodeID, 'color: unset', IkaDebugStyles.ComponentName, 'color: unset'],
            mode: 'error'
        }
    },
    [BindEvent.BindingElementHasNoBindKey](element: Element) {
        return {
            s: `A binding component does not have a bind key attribute.\n${INDENT}Element: $o`,
            o: [element],
            mode: 'warn'
        }
    },
    [BindEvent.InitiatingBinding](componentName: string, parentName: string) {
        return {
            s: `Component %c<${componentName}>%c is sending bind registration to parent %c<${parentName}>`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset, IkaDebugStyles.ComponentName]
        }
    },
    [BindEvent.ReceivedBindingRequest]() { return { s: '' } },
    [BindEvent.RegisteringBinding](key: string, componentName: string) {
        return {
            s: `Registering binding to %c"${key}"%c by component %c<${componentName}>`,
            css: [IkaDebugStyles.VariableValue, 'color: unset', IkaDebugStyles.ComponentName]
        }
    },
    [BindEvent.DeregisteringBinding](key: string, componentName: string) {
        return {
            s: `Deregistering binding to %c"${key}"%c by component %c<${componentName}>`,
            css: [IkaDebugStyles.VariableValue, 'color: unset', IkaDebugStyles.ComponentName]
        }
    },
    [BindEvent.Subscribed](nodeId: string, key: string) {
        return {
            s: `Node %c${nodeId}%c is subscribed to bind key %c"${key}"%c`,
            css: [IkaDebugStyles.NodeID, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue, IkaDebugStyles.Unset]
        }
    },
    [BindEvent.BindingPermissionDenied](nodeId: string, key: string) {
        return {
            s: `Bind by node %c${nodeId} onto key "%c${key}%c" failed as this node is not permissioned.`,
            css: [IkaDebugStyles.NodeID, 'color: unset', IkaDebugStyles.VariableValue, 'color: unset']
        }
    },
    [BindEvent.BindingValueChanged](key: string, source: string, value: any, callbackCount: number) {
        const stringTypes = new Set(['string', 'number', 'boolean', 'undefined', 'null'])
        const valueDisplay = stringTypes.has(typeof value) ? `"%c%o%c"` : `%o`
        return {
            s: `Bind "%c${key}%c" changed, triggering ${callbackCount} callbacks\n${INDENT}Source: %c%o%c\n${INDENT}Value: ${valueDisplay}`,
            o: [source, value],
            css: [IkaDebugStyles.VariableValue, IkaDebugStyles.Unset, IkaDebugStyles.ComponentName, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue, IkaDebugStyles.Unset]
        }
    },
    [BindEvent.ReceivedBindingUpdate](componentName: string, key: string) {
        return {
            s: `%c<${componentName}>%c received binding update for key %c"${key}"`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue]
        }
    },
    [BindEvent.IkaABReceivedBindingUpdate](ref: IkaAttributeBind, bind: string, value, attributes: Array<string>, targets: { [id: string]: Element }) {
        const children = Object.values(targets)
        return {
            s: `%c<ika-ab>%c received binding update.\n` +
                `${INDENT}Bind: %c"${bind}"%c\n` +
                `${INDENT}Value: %o\n` +
                `${INDENT}Attributes: ${attributes.length}, ${attributes.join(', ')}\n` +
                `${INDENT}Targets: ${children.length}, %o\n` +
                `${INDENT}Source: %o`,
            o: [value, children, ref],
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset]
        }
    },
    [BindEvent.NoBindingValueUpdateHandler](tagName: string) {
        return {
            s: `Component %c<${tagName}>%c did not implement state change callback.`,
            css: [IkaDebugStyles.ComponentName, 'color: unset'],
            mode: 'warn'
        }
    },
    [BindEvent.NoParentFound](element: Element) {
        return {
            s: `Could not register bind for %c<${element.tagName.toLowerCase()}>%c as no parent was found.`,
            css: [IkaDebugStyles.ComponentName, 'color: unset'],
            mode: 'warn'
        }
    },
    [BindEvent.UnregisteredNodeSubscribing](k: string) {
        return {
            s: `Subscribing to bind must specify bind key, node ID, and a callback function. Check if the node requesting the binding has registered for a node ID.\nKey: ${k}`,
            mode: 'error'
        }
    },
    [ComponentEvent.Building](component: Element) {
        return {
            s: `Building component %c<${component.tagName.toLowerCase()}>%c\n${INDENT}Component: %o`,
            o: [component],
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset]
        }
    },
    [ComponentEvent.NoStateDataWhenInitialising](componentName: string, instanceSpace: string) {
        return {
            s: `No data is available to initialise states for component %c<${componentName}>%c in instance space "${instanceSpace}".`,
            css: [IkaDebugStyles.ComponentName, 'color: unset', IkaDebugStyles.VariableValue, 'color: unset']
        }
    },
    [ComponentEvent.Built](component: Element) {
        return {
            s: `Component %c<${component.tagName.toLowerCase()}>%c is constructed.\n${INDENT}Component: %o`,
            o: [component],
            css: [IkaDebugStyles.ComponentName, 'color: unset']
        }
    },
    [ComponentEvent.ReceivedEvent](componentName: string, e: Event) {
        const { eventSource, tagName } = getEventSourceAndTagName(e)
        const customType: string = e instanceof CustomEvent ? e.detail?.type : null
        const customTypeMsg = customType ? `${INDENT}Type: %c${customType}%c\n` : ''
        return {
            s: `Component %c<${componentName}>%c received event %c${e.type}%c\n${customTypeMsg}${INDENT}Source: %o`,
            o: [eventSource],
            css: [IkaDebugStyles.ComponentName, 'color: unset', IkaDebugStyles.VariableValue, 'color: unset', IkaDebugStyles.VariableValue, 'color: unset']
        }
    },
    [ComponentEvent.ReceivedEventNotFromElement](eventSource: EventTarget) {
        return { s: `Received an event that did not originate from an HTMLElement. No action taken.`, o: [eventSource] }
    },
    [ComponentEvent.ReceviedComponentCallNoDetail](componentName: string, e: Event) {
        const { eventSource, tagName } = getEventSourceAndTagName(e)
        return {
            s: `%c<${componentName}>%c received component call from %c<${tagName}>%c but it did not contain detail.\nEvent: %o`,
            o: [e]
        }
    },
    [IkaStatus.Initialising](versionNumber: string) {
        return { s: `Ika initialising... v${versionNumber}` }
    },
    [IkaStatus.BufferStart]() {
        return {
            s: 'Buffer processing started at ' + Math.round(performance.measure('ika-buf-started', 'ika-init').duration) + ' ms'
        }
    },
    [UnexpectedError.NotAttributeMutation](m: MutationRecord) {
        return { s: `Unexpected mutation record: This is not a attribute mutation.\n%o`, o: [m], mode: 'error' }
    },
    [UnexpectedError.UnwatchedAttribute](m: MutationRecord) {
        return { s: `Unexpected mutation record: This attribute change is not watched and has no handler.\n%o`, o: [m], mode: 'error' }
    },
    [UnexpectedError.MutationTargetUnexpected](m: MutationRecord) {
        return { s: `Unexpected mutation record: The mutation target is not the expected Ika component.\n%o`, o: [m], mode: 'error' }
    },
    [ImportEvent.ImportWithSameTemplateID](templateId: string, importPath: string) {
        return {
            s: `Import with same template ID detected: Importing %c"${templateId}"%c from %c"${importPath}"%c.`,
            css: [IkaDebugStyles.VariableValue, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue, IkaDebugStyles.Unset],
            mode: 'warn'
        }
    },
    [ImportEvent.ImportComponentWithNoMD5](path: string) {
        return {
            s: `Importing component from "%c${path}"%c but no MD5 was provided. Consider adding the MD5 hash to the component you are importing to detect import conflicts.`,
            css: [IkaDebugStyles.VariableValue, IkaDebugStyles.Unset],
            mode: 'warn'
        }
    },
    [ImportEvent.RemovedNode](path: string, nodes: Array<MutationRecord>, record: { t: Array<Node>, n: Array<Node> }) {
        const msg = record.n.length == 0
            ? `No nodes left in DOM are associated with this import. Template and script nodes will now be removed.`
            : `${record.n.length} nodes associated with this import are left in DOM.`
        return {
            s: `${nodes.length} import related nodes were removed from DOM.\n${msg}\nPath: %c${path}%c\nRemoved: %o\n\nRecord: %o`,
            o: [nodes.flatMap(mr => mr.removedNodes.values()), record],
            css: [IkaDebugStyles.NodeID, IkaDebugStyles.Unset]
        }
    },
    [ImportEvent.ImportedNodes](path: string, nodes: { t: Array<Node>, n: Array<Node> }) {
        return {
            s: `Loaded content from HTML, registered ${nodes.t.length} templates and ${nodes.n.length} nodes.\nPath: %c${path}%c\nNodes: %o`,
            o: [nodes],
            css: [IkaDebugStyles.NodeID, IkaDebugStyles.Unset],
        }
    },
    [MapElementEvent.AwaitCustomElementDefinitionTimeout](elementName: string, timeoutLimit: number) {
        return {
            s: `Custom element %c<${elementName}>%c was not defined within ${timeoutLimit}ms.\nThe element will still be mapped when it's defined, but check if the element name is correct.`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue, IkaDebugStyles.Unset],
            mode: 'warn'
        }
    },
    [MapElementEvent.MappingInitiated](count: number, componentName: string) {
        return {
            s: `%c<ika-me>%c is creating ${count} %c<${componentName}>%c elements...`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset, IkaDebugStyles.ComponentName, IkaDebugStyles.Unset]
        }
    },
    [MapElementEvent.NoBindingDictionary](stateVar: any) {
        return {
            s: `No binding dictionary is supplied to %c<ika-me>%c, and it will therefore be unable to map elements.\nGlobal state variable value: %o`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset],
            o: [stateVar],
            mode: 'error',
        }
    },
    [MapElementEvent.UnexpectedBDType](bdFunction: any) {
        return {
            s: `Unexpected binding dictionary: the type supplied is %c"${typeof bdFunction}"%c.`,
            css: [IkaDebugStyles.VariableValue, IkaDebugStyles.Unset],
            mode: 'error'
        }
    },
    [MapElementEvent.UnexpectedBDStateObject]() {
        return {
            s: `Unexpected binding dictionary state object: it should be a KV of functions.`,
            mode: 'error'
        }
    },
    [MapElementEvent.UnexpectedBDOutputType](output) {
        return {
            s: `Unexpected output from binding dictionary transformation function. Expecting KV pairs, got %c"${typeof output}"%c`,
            css: [IkaDebugStyles.VariableValue, IkaDebugStyles.Unset],
            mode: 'error'
        }
    },
    [MapElementEvent.UnexpectedBDOutputValue](k: string, v) {
        return {
            s: `Unexpected value from binding dictionary transformation output: expecting string or number for key %c"${k}"%c, got %c"${typeof v}"%c.`,
            css: [IkaDebugStyles.VariableValue, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue, IkaDebugStyles.Unset],
            mode: 'error'
        }
    },
    [MapElementEvent.NoMapFrom](mfKey: string) {
        return {
            s: `%c<ika-me>%c: No map from array under key "${mfKey}". %c<ika-me>%c will clear its children.`
        }
    },
    [MapElementEvent.UnexpectedMFType](mappingValue: any) {
        return {
            s: `%c<ika-me>%c is asked to map from a value that is not an array, received %c"${typeof mappingValue}"%c.\nValue: %o`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset, IkaDebugStyles.VariableValue],
            o: [mappingValue],
            mode: 'error'
        }
    },
    [MapElementEvent.NestedIkaMEHasISAttr](element: IkaMapElements) {
        return {
            s: `A nested %c<ika-me>%c element has an instance space attribute. It inherits the top level instance space, and its mf attribute is the sub key in the mapped object.\n%o`,
            css: [IkaDebugStyles.ComponentName, IkaDebugStyles.Unset],
            o: [element],
            mode: 'error'
        }
    },
    [MapElementEvent.StateMappingNotOnIkaComponent](bd: BindingDictionary, elementName: string) {
        return {
            s: `The binding dictionary includes state mapping, but the element mapped is not an Ika component, and therefore cannot be set states.` +
                `\nBinding dictionary: %o\nElement mapped: ${elementName}`,
            o: [bd],
            mode: 'warn'
        }
    },
    [BufferEvent.UnexpectedData](update: { k: string, v }) {
        return {
            s: `Unexpected data type being pushed to Ika Buffer, push rejected.\n%o`,
            o: [update],
            mode: 'error'
        }
    },
    [RouterEvent.OverwritingKey](key: string, router: HTMLElement) {
        return {
            s: `A router already exists under the key ${key}. Overwriting this registration now with ${router}`,
            mode: 'warn'
        }
    },
    [ResourceManagerEvent.FetchFailed](url: RequestInfo | URL, options: RequestInit, status: number, body: string) {
        return {
            s: `Fetching resource failed.\nURL: %o\nOptions: %o\nStatus: ${status}\nBody: ${body}`,
            o: [url, options],
            mode: 'error'
        }
    },
    [ResourceManagerEvent.HasCachedData](url: RequestInfo | URL, options: RequestInit) {
        return {
            s: `Cached data found for the requested resource.\nURL: %o\nOptions: %o`,
            o: [url],
        }
    },
    [ResourceManagerEvent.MakingFetch](url: RequestInfo | URL, options: RequestInit) {
        return {
            s: `Resource not found in cached data, making fetch request.\nURL: %o\nOptions: %o`,
            o: [url, options]
        }
    },
    [ResourceManagerEvent.WritingIntoCache](url: RequestInfo | URL, options: RequestInit, data, fromStatic?: boolean) {
        return {
            s: `Resource loaded from ${fromStatic ? 'static resource' : 'network'}, writing into cache.\nURL: %o\nOptions: %o\nData: %o`,
            o: [url, options, data]
        }
    },
    [ResourceManagerEvent.RequesterHasNoId](url: string) {
        return {
            s: `A node that does not have an ID requested a resource from Resource Manager.\nURL: ${url}`,
            mode: 'warn'
        }
    }
})

export default en_gb;