import BindingComponent, { registerBind } from "../../core/bind";
import StateBindRegister from "../../core/sbr";
import { BindCallback, BindingEvent, ika, IkaInitObject, IKA_VERSION } from "../../ika";
import { attachDebugButton } from "../../debug";
import { addAttributeListener, AttributeChangedHandler } from "./addAttributeListener";
import { setComponentTemplate } from "./setComponentTemplate";
import { ComponentEvent } from "../../types/debug";
import { addEventListeners } from "./addEventListeners";

export type IkaComponentOptions = {
    templateId?: string,
    bundle?: IkaInitObject[string],
    construct?: (this: IkaComponent, c: IkaComponent) => void,
    eventBinds?: { [eventType: string]: (e: Event | CustomEvent) => void },
    connectedCallback?: (c: IkaComponent) => void;
    disconnectedCallback?: (c: IkaComponent) => void;
    adoptedCallback?: (c: IkaComponent) => void;
    attributesWatched?: { [type: string]: AttributeChangedHandler }
    attributeChangedCallback?: AttributeChangedHandler,
    noTemplate?: boolean
}

const BIND_ATTRIBUTE_FLAG = 'ika:b';

export class IkaComponent extends BindingComponent {
    #stateBinds: StateBindRegister;
    #options: IkaComponentOptions = {}
    #methods = {}
    #onNodeRegisteredQueue: Array<any> = []
    #constructCompleted: Promise<any>;

    constructor(options: IkaComponentOptions) {
        super();
        let constructCompletedResolver;
        this.#constructCompleted = new Promise(r => constructCompletedResolver = r)

        this.#stateBinds = new StateBindRegister(this)
        this.#methods = options?.bundle?.m
        this.#options = options || {}

        this.#options.attributesWatched ??= {}
        this.#options.attributesWatched[BIND_ATTRIBUTE_FLAG] = ikaBAttributeChangeHandler

        addEventListeners.bind(this)(options?.eventBinds)

        const wrappedPostTemplateActions: () => void = postTemplateActions.bind(this)
        options?.noTemplate
            ? wrappedPostTemplateActions()
            : setComponentTemplate.bind(this)(options?.templateId, options?.bundle?.s)
                .then(wrappedPostTemplateActions)

        function postTemplateActions(this: IkaComponent) {
            addAttributeListener.bind(this)(this.#options.attributesWatched, options?.attributeChangedCallback)

            try {
                options?.construct && options.construct.bind(this)(this)
                // Unify this with link state node registration request? Not sure what happens when both are called
                if (this.#onNodeRegisteredQueue.length > 0) {
                    ika.reg.registerNode(this, this, this.registeredCallback)
                }
                constructCompletedResolver()

                IKA_CONFIG.debugMode && attachDebugButton(this)
                ika.print(ComponentEvent.Built, this)
            } catch (e) {
                // TO ADD: If construct fails, print error and give option to resolve construct is completed anyway,
                //         so that bundled functions can be invoked even if construct script is not run successfully.
            }
        }

    }

    connectedCallback() { this.runLifecycleMethodIfExists('connectedCallback') }
    disconnectedCallback() { this.runLifecycleMethodIfExists('disconnectedCallback') }
    adoptedCallback() { this.runLifecycleMethodIfExists('adoptedCallback') }

    runLifecycleMethodIfExists(name: 'connectedCallback' | 'disconnectedCallback' | 'adoptedCallback') {
        if (name in this.#options) { this.#options[name](this) }
    }

    getIkaVersion() { return IKA_VERSION }

    registeredCallback(id: string, n: Element, requestor: BindingComponent) {
        requestor.setNodeId(id)
        requestor.registerBindsWithParentComponent.bind(requestor)()
        //@ts-ignore
        requestor.getOnNodeRegisteredQueue().forEach(cb => cb())
    }

    registerBindsWithParentComponent() {
        const isGlobalBind = this.hasAttribute('global')
        Object.values(this.dataset).forEach(bind => {
            registerBind({
                requester: this as any,
                bind: bind,
                global: isGlobalBind,
            })
        })
    }

    async invokeFunction(name: string, ...args: Array<any>) {
        await this.#constructCompleted

        if (!this.#methods || !this.#methods[name]) {
            console.warn(`Check that the function binding "${name}" is correct, as it is not found for the component.`);
            return;
        }
        try {
            return this.#methods[name].bind(this)(...args)
        } catch (e) {
            console.error(`Error while invoking function "${name}" on component <${this.tagName.toLowerCase()}>`, e)
        }
    }

    async subscribeToBind(k: string, id: string, cb: BindCallback) { this.#stateBinds.subscribeToBind(k, id, cb) }
    unsubscribeToBind(id: string, k?: string) { this.#stateBinds.unsubscribeToBind(id, k) }
    bindValueChanged(update: BindingEvent.ValueUpdate) {
        const statesToUpdate = Object.entries(this.dataset).filter(([state, bind]) => update.k == bind)
        // States to update should only be 1 entry, since it does not make sense to bind multiple states to the same parent state
        statesToUpdate.forEach(([state, bind]) => this.setState(state, update.v))
    }

    getState(key: string) { return this.#stateBinds.getValue(key) }
    setState(key: string, val) { val !== undefined && this.#stateBinds.setValue(key, val) }
    linkState(this: IkaComponent, baseKey: string, transformation: (v: any) => any, linkedKey?: string) {
        this.#onNodeRegisteredQueue.push(link.bind(this))
        if (!this.getNodeId()) { ika.reg.registerNode(this, this, this.registeredCallback) }

        function link(this: IkaComponent) {
            const boundTransformation = linkedKey
                ? (update) => this.setState(linkedKey, transformation.bind(this)(update.v))
                : (update) => transformation.bind(this)(update.v);

            this.subscribeToBind(baseKey, this.getNodeId(), boundTransformation)
        }
    }

    getOnNodeRegisteredQueue() { return [...this.#onNodeRegisteredQueue] }
    onNodeRegistered(cb: () => void) {
        if (this.getNodeId()) { cb.bind(this)(); return }
        this.#onNodeRegisteredQueue.push(cb.bind(this))
    }

    getRegistrationTargets(): Array<Element> { return [] }

    debugPrint() { console.log(this.#stateBinds.listStates()) }
}

function ikaBAttributeChangeHandler(this: IkaComponent, n, o, v) {
    if (this.hasAttribute(BIND_ATTRIBUTE_FLAG)) {
        if (!this.getNodeId()) {
            ika.reg.registerNode(this, this, this.registeredCallback)
        } else {
            this.registerBindsWithParentComponent()
        }
    }
}