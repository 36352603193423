import { ika, IkaComponent } from "../ika";
import { IkaDebugStyles } from "../debug";
import { BindingEvent, ComponentCall, CustomEventTypes } from "../types/events";
import BindingComponent from "../core/bind";

export default class IkaStateDependentContainer extends BindingComponent {
    #chain: Array<string> = []
    #dependents: Array<IkaStateDependentContainer> = []
    constructor() {
        super()
        //@ts-ignore
        this.addEventListener(CustomEventTypes.ComponentCall, this.addDependentSDC)
    }

    bindValueChanged(update: BindingEvent.ValueUpdate) {
        const isChildSdcOfAffectedSdc = this.#chain.some(b => b == update.k)
        const hasDependentSdcs = this.#dependents.length > 0

        if (!isChildSdcOfAffectedSdc && hasDependentSdcs) {
            ika.debug(`The <ika-sdc> has affected child`)
            // Dependent SDCs in this SDC's child nodes and their node trees are removed from the component's binding callbacks.
            this.#dependents.forEach(c => c.deregisterBindsWithParentComponent())
            this.#dependents = []
        }

        this.#attachSDT()
    }

    addDependentSDC(e: CustomEvent<ComponentCall.EventDetails>) {
        if (e.detail.type == ComponentCall.EventType.EstablishChain && !(e.composedPath()[0] as Node).isSameNode(this)) {
            const dependentElement = e.detail.payload.issuer
            ika.debug(`<ika-sdc> received an EstablishChain event, adding a <${dependentElement.tagName.toLowerCase()}> to depedents`)
            this.#dependents.push(dependentElement)
        }
    }

    establishChainCb(path: EventTarget[], component: IkaComponent) {
        this.setComponent(component)
        path.forEach((n, i) => {
            if (n instanceof IkaStateDependentContainer && i != 0) {
                this.#chain.unshift(n.getAttribute('b'))
            }
        })

        this.#chain.length > 0 && ika.debug(`<ika-sdc> registered a SDC dependency chain of [${this.#chain.join(', ')}]`)
    }

    onParentComponentLoaded() {
        const detail: ComponentCall.EventDetail.EstablishChain = {
            type: ComponentCall.EventType.EstablishChain,
            nodeId: this.getNodeId(),
            payload: { issuer: this }
        }
        const establishChainEvent = new CustomEvent(CustomEventTypes.ComponentCall, {
            bubbles: true,
            composed: true,
            cancelable: true,
            detail: detail
        })

        ika.debug(`%c<${this.tagName.toLowerCase()}>%c component triggering establish chain event.`, [IkaDebugStyles.ComponentName, 'color: unset'])
        this.dispatchEvent(establishChainEvent)
    }

    #attachSDT() {
        const componentName = this.getComponent().tagName.toLowerCase()
        const bind = this.getAttribute('b')
        const ikaTemplatesChildren = document.getElementsByTagName('ika:templates')[0].children

        const sdt = getElement('sdt', ikaTemplatesChildren, sdt => sdt.getAttribute('cn') && sdt.getAttribute('cn').toLowerCase() == componentName);
        const stc = getElement('stc', sdt.children, stc => stc.getAttribute('b') == bind)
        const tamc = getElement('tamc', stc.children, tamc => tamc.getAttribute('ts') == this.getAttribute('ts'))
        const template = getElement('template', tamc.children,
            template => template instanceof HTMLElement && template.dataset.a == this.getComponent().getState(bind))

        if (!template) {
            // No template matches the state value, display nothing for the SDC.
            this.replaceChildren()
        } else {
            // Adding SDC children will trigger their constructors firing off EstablishChain events, which will be listend to by this component.
            // No need to register them here.
            if (!(template instanceof HTMLTemplateElement)) {
                console.error(`Retrieved template arm match element isn't a template.`); return;
            }
            const clonedNode = template.content.cloneNode(true)
            this.replaceChildren(...clonedNode.childNodes)
        }

        function getElement(type: 'template' | 'sdt' | 'stc' | 'tamc', collection: HTMLCollection, filter: (e: Element) => boolean) {
            const result = Array.from(collection).filter(filter)
            checkIfMoreThanOne(type, result);
            return result[0]
        }

        function checkIfMoreThanOne(type, arr) {
            const tag = type == 'template' ? '<template>' : `<ika:${type}>`
            if (tag == '<template>') {
                arr.length == 0 && ika.debug(`No ${tag} is a match for the current state.`)
            } else {
                if (arr.length > 1) {
                    console.error(`More than one ${tag} is defined for component "${componentName}"`)
                } else if (arr.length == 0) {
                    console.error(`No ${tag} is defined for component "${componentName}"`)
                }
            }
        }
    }
}