import { ika } from "../../ika";
import { UnexpectedError } from "../../types/debug";
import { IkaComponent, IkaComponentOptions } from "./ikaComponent";

export type AttributeChangedHandler = (this: IkaComponent, name: string, oldVal: string, newVal: string) => void

export function addAttributeListener(this: IkaComponent, attributesWatched?: IkaComponentOptions['attributesWatched'], defaultCallback?: AttributeChangedHandler) {
    if (!defaultCallback && (attributesWatched == null || Object.keys(attributesWatched).length == 0)) return;

    const attributeNamesWatched = attributesWatched ? Object.keys(attributesWatched) : [];
    const observer = new MutationObserver((mutations: Array<MutationRecord>, o: MutationObserver) => {
        mutations.forEach(mutation => {
            if (!validateMutationRecord(mutation, this, defaultCallback != null, attributeNamesWatched)) return
            const oldVal = mutation.oldValue;
            const newVal = mutation.target instanceof HTMLElement && mutation.target.getAttribute(mutation.attributeName);
            getCallback.bind(this)(mutation.attributeName, attributesWatched, defaultCallback)(mutation.attributeName, oldVal, newVal);
        });
    });

    const observerOptions: MutationObserverInit = {
        attributes: true,
        attributeOldValue: true
    }
    if (!defaultCallback) { observerOptions.attributeFilter = attributeNamesWatched }

    observer.observe(this, observerOptions);

    // Initialise the watched attributes
    Array.from(this.attributes).forEach(attr => {
        if (!defaultCallback && !(attr.name in attributesWatched)) return
        getCallback.bind(this)(attr.name, attributesWatched, defaultCallback)(attr.name, null, attr.value)
    })
}

function validateMutationRecord(m: MutationRecord, c: IkaComponent, hasDefaultCallback: boolean, attributeNamesWatched: Array<string>) {
    if (m.type != 'attributes') {
        ika.print(UnexpectedError.NotAttributeMutation, m)
        return;
    }
    if (!hasDefaultCallback && !(attributeNamesWatched.includes(m.attributeName))) {
        ika.print(UnexpectedError.NotAttributeMutation, m)
        return;
    }
    if (!m.target.isSameNode(c)) {
        ika.print(UnexpectedError.MutationTargetUnexpected, m);
        return;
    }
    return true
}

function getCallback(this: IkaComponent, attributeName: string, attributesWatched: IkaComponentOptions['attributesWatched'], defaultCallback?: AttributeChangedHandler)
    : AttributeChangedHandler {
    return (attributesWatched && attributesWatched[attributeName] || defaultCallback).bind(this)
}