export class InitBundle {
    #data = {}

    constructor() { }
    add(cn: string, bundle) {
        if (typeof cn != 'string') {
            throw 'Initialisation bundle object must have a string component name.';
        }
        this.#data[cn] = bundle;
    }
    get(cn: string) {
        // Not deep cloned! For safety, should be copied.
        return this.#data[cn];
    }
}
