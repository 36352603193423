import { ika } from "../../ika";
import { ComponentEvent } from "../../types/debug";
import { IkaComponentOptions } from "./ikaComponent";

export async function setComponentTemplate(templateId: string, initBundle?: IkaComponentOptions['bundle']['s']) {
    const id = templateId || this.tagName.toLowerCase();
    const name = this.tagName.toLowerCase();
    const template = await ika.reg.getComponentTemplate(id) as HTMLTemplateElement;

    if (!template || !(template instanceof HTMLTemplateElement)) {
        console.error(`Error creating component ${name}:\n A template with ID ${(id)} is not found.`);
        return;
    } else if (template.tagName !== 'TEMPLATE') {
        throw new Error(`Template element with id ${id} is not a template`);
    } else {
        ika.print(ComponentEvent.Building, this)
    }

    const is = this.getAttribute('ika:is') || '_default';

    if (!initBundle || !initBundle[is]) {
        ika.print(ComponentEvent.NoStateDataWhenInitialising, this.tagName.toLowerCase(), is)
    } else {
        Object.entries(initBundle[is]).forEach(([key, value]) => this.setState(key, value));
    }

    const clonedNode = template.content.cloneNode(true) as DocumentFragment;
    const notSlotChildNodes = Array.from(this.childNodes).filter(n => !(n instanceof Element) || (n instanceof Element && !n.hasAttribute('slot')))
    this.attachShadow({ mode: 'open' }).append(clonedNode, ...notSlotChildNodes);
}
