import { ObjectEntriesForEach } from '../../common/shortcuts';
import { ika, IkaComponent } from '../../ika';
import { MapElementEvent } from '../../types/debug';
import { BindingDictionary } from './types';

export function applyBindingDictionary(target: Element, bd: BindingDictionary, data: any, index: number) {
    if (bd) {
        validateOutputAndApply('attr', target, bd, data, index);
        validateOutputAndApply('prop', target, bd, data, index);
        if (bd.states) {
            const tagName = target.tagName.toLowerCase();

            if (target.tagName.includes('-')) {
                customElements.whenDefined(tagName).then(() => {
                    if (target instanceof IkaComponent) {
                        ObjectEntriesForEach(bd.states, ([key, transformationFn]) => {
                            target.setState(key, transformationFn(data, index));
                        });
                    } else { printException() }
                });
            } else { printException() }

        }
    }
    function printException() {
        ika.print(MapElementEvent.StateMappingNotOnIkaComponent, bd, target.tagName.toLowerCase())
    }
}

type ValidationMode = Exclude<keyof BindingDictionary, 'states'>;
function validateOutputAndApply(type: ValidationMode, target: Element, bd: BindingDictionary, data: any, index: number) {
    const applyFn = type == 'attr'
        ? ([attr, val]) => target.setAttribute(attr, val)
        : ([prop, val]) => target[prop] = val;

    if (type in bd) {
        const output = bd[type](data, index);

        if (output == null) { return; }
        if (typeof output != 'object') {
            ika.print(MapElementEvent.UnexpectedBDOutputType, output); return;
        }

        ObjectEntriesForEach(output, ([k, v]) => {
            if (!['string', 'number'].includes(typeof v)) {
                ika.print(MapElementEvent.UnexpectedBDOutputValue, k, v); return;
            }
            applyFn([k, v]);
        });
    }
}
