import { ika } from "../ika";
import { UpdateType } from "../types/buffer";
import { BindingEvent } from "../types/events";
import BindingComponent, { getParentIkaComponent, registerBind } from "../core/bind";
import { BindEvent } from "../types/debug";
import IkaMapElements from "./me";


export default class IkaAttributeBind extends BindingComponent {
    #attrBinds: Array<[attr: string, bind: string]> = []
    #childIds: { [id: string]: Element } = {}

    constructor() {
        super();
        this.processBinds.bind(this)()
        this.hasAttribute('unwrap') && this.before(...this.childNodes)
    }

    async processBinds() {
        const context = this.hasAttribute('context')
            ? (await getParentIkaComponent(this, true)).getState(this.getAttribute('context'))
            : null
        this.#attrBinds = Object.entries(this.dataset).map(([attr, bind]) => [
            attr.replace(/[A-Z]/, `-$&`),
            context ? `${bind}_${context}` : bind
        ])
    }

    getRegistrationTargets(): Array<Element> {
        return [this, ...this.children]
    }

    registeredCallback(id: string, n: Element, r: IkaAttributeBind) {
        if (n.isSameNode(r)) {
            r.setNodeId(id)
            r.registerBindsWithParentComponent.bind(r)()
        } else {
            r.setChildId.bind(r)(id, n);
        }
    }

    setChildId(id: string, n: Element) {
        this.#childIds[id] = n;
        if (n.tagName.includes('-')) {
            customElements.whenDefined(n.tagName.toLowerCase()).then(() => {
                if (n instanceof BindingComponent || n instanceof IkaMapElements) { n.setNodeId(id) }
            })
        }
    }

    registerBindsWithParentComponent() {
        this.#attrBinds.forEach(([attr, bind]) => {
            registerBind({
                requester: this,
                bind: bind,
                global: this.hasAttribute('global')
            })
        })
    }

    bindValueChanged(update: BindingEvent.ValueUpdate) {
        // Multiple attributes could be bound to a single state key
        const targetAttributes = this.#attrBinds.filter(([attr, bind]) => bind == update.k).map(([attr, bind]) => attr)
        // const context = this.getAttribute('context')

        ika.print(BindEvent.IkaABReceivedBindingUpdate, this, update.k, update.v, targetAttributes, this.#childIds)

        Array.from(this.children).length != Object.keys(this.#childIds).length
            && ika.debug(`<ika-ab> current children do not match number of childIds. They may have been moved out of <ika-ab>'s child nodes.`)

        targetAttributes.forEach(attr => {
            Object.keys(this.#childIds).forEach(id => {
                const isProp = attr.charAt(0) == '.'
                ika.buffer.push({
                    n: id,
                    type: isProp ? UpdateType.PROP : UpdateType.ATTR,
                    k: isProp ? attr.substring(1) : attr,
                    v: update.v
                })
            })
        })
    }
}