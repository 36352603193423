export enum NodeEvent {
    Registered = 'registered',
    Deregistered = 'deregistered',
    AlreadyRegistered = 'already-registered',
    NodeAlreadyHasId = 'node-already-has-id',
}
export enum BindEvent {
    BindingElementHasNoBindKey = 'binding-element-has-no-bind-key',
    InitiatingBinding = 'initiating-binding',
    ReceivedBindingRequest = 'received-binding-request',
    RegisteringBinding = 'registering-binding',
    DeregisteringBinding = 'deregistering-binding',
    Subscribed = 'subscribed',
    BindingValueChanged = 'binding-value-changed',
    ReceivedBindingUpdate = 'received-binding-update',
    BindingPermissionDenied = 'binding-permission-denied',
    IkaABReceivedBindingUpdate = 'ika-ab-received-binding-update',
    NoBindingValueUpdateHandler = 'no-binding-value-update-handler',
    NoParentFound = 'binding-element-no-parent-found',
    UnregisteredNodeSubscribing = 'unregistered-node-subscribing',
}

export enum ComponentEvent {
    Building = 'building',
    Built = 'built',
    NoStateDataWhenInitialising = 'no-state-data-when-initialising',
    ReceivedEvent = 'received-event',
    ReceivedEventNotFromElement = 'received-event-not-from-element',
    ReceviedComponentCallNoDetail = 'received-component-call-no-detail',
}

export enum IkaStatus {
    Initialising = 'initialising-ika',
    BufferStart = 'buffer-start',
}

export enum UnexpectedError {
    NotAttributeMutation = 'not-attribute-mutation',
    UnwatchedAttribute = 'unwatched-attribute',
    MutationTargetUnexpected = 'mutation-target-unexpected',
}

export enum ImportEvent {
    ImportedNodes = 'imported-nodes',
    RemovedNode = 'mut-observer-removed-node',
    ImportWithSameTemplateID = 'import-with-same-template-id',
    ImportComponentWithNoMD5 = 'import-component-with-no-md5',
}

export enum MapElementEvent {
    AwaitCustomElementDefinitionTimeout = 'await-timeout',
    MappingInitiated = 'mapping-initiated',
    NoBindingDictionary = 'no-bd',
    UnexpectedBDType = 'unexpected-bd-type',
    UnexpectedBDStateObject = 'unexpected-bd-state-object',
    UnexpectedBDOutputType = 'unexpected-bd-output-type',
    UnexpectedBDOutputValue = 'unexpected-bd-output-val',
    NoMapFrom = 'no-map-from',
    UnexpectedMFType = 'unexpected-mf-type',
    NestedIkaMEHasISAttr = 'nested-ika-me-has-is-attr',
    StateMappingNotOnIkaComponent = 'state-mapping-not-on-ika-component',
}

export enum BufferEvent {
    UnexpectedData = 'unexpected-data',
}

export enum RouterEvent {
    OverwritingKey = 'overwriting-router-key',
}

export enum ResourceManagerEvent {
    HasCachedData = 'has-cached-data',
    MakingFetch = 'making-fetch',
    FetchFailed = 'fetch-failed',
    WritingIntoCache = 'writing-into-cache',
    RequesterHasNoId = 'requester-has-no-id',
}


export type DebugFilter =
    ComponentEvent | BindEvent | NodeEvent | IkaStatus | UnexpectedError |
    ImportEvent | MapElementEvent | BufferEvent | RouterEvent | ResourceManagerEvent