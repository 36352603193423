import { BindCallback, BindingEvent, ika } from "../ika"
import { ResourceManagerEvent } from "../types/debug"

export type IkaStatic = {
    url: string,
    options?: RequestInit,
    headers: Headers, // Is response headers, not request headers
    data: string
}

const RESOURCE_MANAGER_GLOBAL_BINDING_PREFIX = 'ika:static'

export class ResourceManager {
    constructor() { }

    async request(nodeId: string, cb: BindCallback, url: string | URL, options?: RequestInit): Promise<void> {
        const [urlString, globalBindingKey] = getUrlStringAndBindingKey(url)

        if (!nodeId) {
            ika.print(ResourceManagerEvent.RequesterHasNoId, urlString); return;
        }

        const hasCacheddata = this.#checkCache(globalBindingKey, options)
        hasCacheddata && ika.print(ResourceManagerEvent.HasCachedData, url, options)

        ika.reg.subscribeToBind(globalBindingKey, nodeId, (u) => wrappedCb(u, cb, options))

        // If already cached, the callback will be called immediately by the binding subscription
        if (hasCacheddata) return

        ika.print(ResourceManagerEvent.MakingFetch, url, options)
        const res = await fetch(url, options)
        if (res.ok) {
            const isJSONResponse = res.headers.get('content-type')?.includes('application/json')
            this.cache(
                urlString,
                res.headers,
                isJSONResponse ? await res.json() : await res.text(),
                options
            )
        } else {
            ika.print(ResourceManagerEvent.FetchFailed, url, options, res.status, await res.text())
        }
    }

    cache(url: string | URL, headers: Headers, data, requestOptions?: RequestInit, fromStatic?: boolean) {
        ika.print(ResourceManagerEvent.WritingIntoCache, url, requestOptions, data, fromStatic)

        const [u, key] = getUrlStringAndBindingKey(url)
        const cacheArray: ResourceCacheArray = [...(ika.reg.getBindValue(key) ?? [])]
        const newEntry: ResourceCacheArray[number] = {
            options: requestOptions,
            headers: headers,
            data: data
        }
        cacheArray.push(newEntry)
        ika.reg.setBindValue(key, cacheArray)
    }
    #checkCache(key: string, options?: RequestInit) {
        const cache: Array<Omit<IkaStatic, 'url'>> = ika.reg.getBindValue(key)
        if (!cache) return null
        return getMatchingRequest(cache, options)
    }
}

function cleanOptions(options: RequestInit) {
    if (!options) return null

    const headers = { ...options.headers }
    if (headers['Authorization']) { delete headers['Authorization'] }
    return { ...options, headers: headers }
}

function wrappedCb(u: BindingEvent.ValueUpdate<Array<Omit<IkaStatic, 'url'>>>, cb: BindCallback, options?: RequestInit) {
    // The global binding update will be called with all request options included
    // The resource manager should trigger a callback only when request options match the initial request
    const matchedRequestCache = getMatchingRequest(u.v, options)
    matchedRequestCache && cb({ k: u.k, v: matchedRequestCache })
}

type ResourceCacheArray = Array<Omit<IkaStatic, 'url'>>

function getMatchingRequest(cacheArray: ResourceCacheArray, options?: RequestInit) {
    const cleanedOptions = cleanOptions(options)
    return cacheArray.find(cached =>
        !options || KVsAreEqual(cached.options, cleanedOptions)
    )
    function KVsAreEqual(a, b) {
        return Object.entries(a).every(([k, v]) =>
            k in b && JSON.stringify(b[k]) == JSON.stringify(v)
        )
    }
}

function getUrlStringAndBindingKey(url: string | URL) {
    const urlString = url instanceof URL ? url.href : url
    const key = `${RESOURCE_MANAGER_GLOBAL_BINDING_PREFIX}:${urlString}`
    return [urlString, key]
}