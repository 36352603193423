import { IkaDebugStyles } from "../debug";
import { elementIsIkaComponent, ika, IkaComponent } from "../ika";
import { BindEvent, NodeEvent } from "../types/debug";

import { ComponentCall, CustomEventTypes, BindingEvent } from "../types/events";

export default class BindingComponent extends HTMLElement {
    #nodeId = null
    #component: IkaComponent = null

    constructor() {
        super();
        const regTargets = this.getRegistrationTargets()
        regTargets.forEach(n =>
            ika.reg.registerNode(this, n, this.registeredCallback)
        )
    }

    getRegistrationTargets(): Array<Element> {
        return [this]
    }

    registeredCallback(id: string, n: Element, requestor: BindingComponent) {
        requestor.setNodeId(id)
        requestor.registerBindsWithParentComponent.bind(requestor)()
    }

    setComponent(t: IkaComponent) { this.#component = t }
    getComponent() { return this.#component }

    setNodeId(id: string) {
        this.#nodeId && this.#nodeId != id &&
            ika.print(NodeEvent.NodeAlreadyHasId, this.#nodeId, this)

        this.#nodeId ??= id
    }
    getNodeId() { return this.#nodeId }

    registerBindsWithParentComponent() {
        const bindKey = this.getAttribute('b')
        if (!bindKey || bindKey == '') {
            ika.print(BindEvent.BindingElementHasNoBindKey, this)
            return
        }
        registerBind({
            requester: this,
            bind: bindKey,
            global: this.hasAttribute('global')
        })
    }
    deregisterBindsWithParentComponent() {
        deregisterBind({
            requester: this,
            bind: this.getAttribute('b'),
            global: this.hasAttribute('global')
        })
    }

    onParentComponentLoaded() { }

    bindValueChanged(update: BindingEvent.ValueUpdate) {
        ika.print(BindEvent.NoBindingValueUpdateHandler, this.tagName.toLowerCase())
    }
}

type RegisterBindProps = {
    requester: BindingComponent,
    bind: string,
    global?: boolean,
}

export async function registerBind(prop: RegisterBindProps) {
    ika.print(BindEvent.RegisteringBinding, prop.bind, prop.requester.tagName.toLowerCase())

    if (!prop.global) {
        let parentComponent = await getParentIkaComponent(prop.requester)
        // no parent will result in unresolving get parent promise since callback will never be called to resolve. 

        const parentComponentName = parentComponent ? parentComponent.tagName.toLowerCase() : null
        parentComponent
            ? customElements.whenDefined(parentComponentName).then(() => sendMsgAndInit(prop.requester, parentComponent, prop.bind))
            : ika.print(BindEvent.NoParentFound, prop.requester)
    } else {
        ika.reg.subscribeToBind(prop.bind, prop.requester.getNodeId(), prop.requester.bindValueChanged.bind(prop.requester))
    }
}


function sendMsgAndInit(requester: BindingComponent, parentComponent: IkaComponent, bind: string) {
    try {
        ika.print(BindEvent.InitiatingBinding, requester.tagName.toLowerCase(), parentComponent.tagName.toLowerCase())
        requester.dispatchEvent(getRegistrationEvent(true, requester, bind))

        requester.onParentComponentLoaded()
        const initState = parentComponent.getState(bind)
        initState && requester.bindValueChanged({ k: bind, v: initState })
    } catch (e) {
        console.error(`Ika error - Could not send registration`)
        console.log({ requester: requester, parentComponent: parentComponent })
        throw e
    }
}

export async function deregisterBind(prop: RegisterBindProps) {
    ika.print(BindEvent.DeregisteringBinding, prop.bind, prop.requester.tagName.toLowerCase())

    if (!prop.global) {
        const parentComponent = await getParentIkaComponent(prop.requester) as IkaComponent
        parentComponent
            ? prop.requester.dispatchEvent(getRegistrationEvent(false, prop.requester, prop.bind))
            : console.warn(`Registration failed for <${prop.requester.tagName.toLowerCase()}>: no parent component found.`)
    }
}

function getRegistrationEvent(register: boolean, requester: BindingComponent, bind: string) {
    const leap = Number.parseInt(requester.getAttribute('leap'))
    const detail: ComponentCall.EventDetail.RegisterBind | ComponentCall.EventDetail.DeregisterBind = {
        type: register ? ComponentCall.EventType.RegisterBind : ComponentCall.EventType.DeregisterBind,
        nodeId: requester.getNodeId(),
        payload: {
            bind: bind,
            issuer: requester,
            leap: Number.isNaN(leap) ? null : leap
        }
    }
    return new CustomEvent(CustomEventTypes.ComponentCall, {
        bubbles: true,
        composed: true,
        cancelable: true,
        detail: detail
    })
}

export async function getParentIkaComponent(c: Element, ignoreLeap?: boolean): Promise<IkaComponent> {
    if (!(c instanceof Element)) {
        console.error(`An object that is not a DOM element has been passed into getParentIkaComponent().`);
        console.log({ element: c, ignoreLeap: ignoreLeap })
        return
    }


    let leaps = ignoreLeap ? 0 : Number.parseInt(c.getAttribute('leap'))
    leaps = Number.isNaN(leaps) ? 0 : leaps

    let parentCustomElement = c

    for (let i = 0; i <= leaps; i++) {
        do {

            parentCustomElement = getParentCustomElement(parentCustomElement)
            if (!(parentCustomElement instanceof HTMLElement)) {
                console.log({ start: c, leaps: leaps, i: i, parent: parentCustomElement })
                console.error(`Could not find parent Ika component.`)
                return
            }

            await customElements.whenDefined(parentCustomElement.tagName.toLowerCase())

        } while (!(!parentCustomElement || elementIsIkaComponent(parentCustomElement)))
    }

    return parentCustomElement as IkaComponent


    function getParentCustomElement(target: Element) {
        let parent = target, isCustomComponent = false, hasParent = true;

        do {
            // @ts-ignore
            parent = parent.parentElement || parent.parentNode.host
            if (!parent) {
                hasParent = false
            } else {
                isCustomComponent = parent.tagName.includes('-')
            }
        } while (!isCustomComponent && hasParent)

        return parent
    }
}