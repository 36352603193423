import { ComponentCall, CustomEventTypes, ika, IkaComponent } from "../../ika";
import { IkaDebugStyles } from "../../debug";
import { ComponentEvent } from "../../types/debug";

// export function processEvent(this: IkaComponent, e: Event | CustomEvent, handler: (e: Event | CustomEvent) => void) {
//     switch (e.type) {
//         case CustomEventTypes.ComponentCall:
//             'detail' in e
//                 ? processComponentCall.bind(this)(e)
//                 : ika.print(ComponentEvents.ReceviedComponentCallNoDetail, componentName, e)
//             break;
//         default: e instanceof CustomEvent
//             ? null
//             : processStandardEvent.bind(this)(e);
//     }
// }

export function processComponentCall(this: IkaComponent, e: CustomEvent<ComponentCall.EventDetails>) {
    const eventSource = e.composedPath()[0];

    if (!checkIfShouldHandle.bind(this)(e)) return;
    if (!(e instanceof CustomEvent)) {
        ika.print(ComponentEvent.ReceviedComponentCallNoDetail, this.tagName.toLowerCase(), e);
        return;
    }

    e.stopPropagation();

    function checkIfShouldHandle(this: IkaComponent, e: Event | CustomEvent) {
        // Ignore events fired off from the component itself
        if (eventSource instanceof Node && eventSource.isSameNode(this)) { return false; }

        // If the event is a component call and has non-zero leaps, it should go to a higher level component.
        const leapTypes = [
            ComponentCall.EventType.RegisterBind,
            ComponentCall.EventType.DeregisterBind,
            ComponentCall.EventType.GetComponent,
            ComponentCall.EventType.InvokeFunction
        ];

        if (e.type == CustomEventTypes.ComponentCall && e instanceof CustomEvent && leapTypes.includes(e.detail.type)) {
            const detail: ComponentCall.EventDetail.RegisterBind | ComponentCall.EventDetail.DeregisterBind = e.detail;
            const leaps = detail.payload.leap;

            if (Number.isInteger(leaps) && leaps > 0) {
                detail.payload.leap--;
                return false;
            }
        }
        return true;
    }

    switch (e.detail.type) {
        case ComponentCall.EventType.RegisterBind: {
            const payload = e.detail.payload;
            const bind = payload.bind;
            const issuer = payload.issuer;

            if (!issuer.getNodeId || !issuer.bindValueChanged) {
                throw `Component <${this.tagName.toLowerCase()}> received a register bind event, but the event issuer is not a binding component.`;
            }

            this.subscribeToBind.bind(this)(bind, issuer.getNodeId(), issuer.bindValueChanged.bind(issuer));
            break;
        }
        case ComponentCall.EventType.DeregisterBind: {
            const payload = e.detail.payload;
            this.unsubscribeToBind.bind(this)(payload.issuer.getNodeId());
            break;
        }
        case ComponentCall.EventType.EstablishChain: {
            const payload = e.detail.payload;
            if (['IKA-SDC'].includes(payload.issuer.tagName)) {
                // @ts-ignore
                (d.payload.issuer as IkaSDC).establishChainCb(e.composedPath(), c);
            }
            break;
        }
        case ComponentCall.EventType.GetComponent: {
            const payload = e.detail.payload;
            payload.cb(this);
            break;
        }
        case ComponentCall.EventType.InvokeFunction: {
            this.invokeFunction(e.detail.payload.key, e.detail.payload.invocationEvent, e.detail.payload.params);
            break;
        }
        default: console.error(`Unrecognised component call type. The event call is %o`, e);
    }
}

function processStandardEvent(this: IkaComponent, e: Event, c: IkaComponent) {
    const functionkey = validateAndGetFunctionkey();
    functionkey && c.invokeFunction(functionkey);

    function validateAndGetFunctionkey() {
        const clickSource = getElementWithIkaBFAttribute(e.composedPath());

        if (!clickSource) return;

        if (!clickSource.dataset || Object.entries(clickSource.dataset).length == 0) {
            console.warn(`Element <${clickSource.tagName.toLowerCase()}> implemented ika:bf attribute but did not bind to a function.`);
            return;
        }

        const functionKey = clickSource.dataset[e.type];
        if (!functionKey) {
            ika.debug(`No bind function is specified for event type %c${e.type}%c in element %c<${clickSource.tagName.toLowerCase()}>`,
                [IkaDebugStyles.VariableValue, 'color: unset', IkaDebugStyles.ComponentName]);
            return;
        }

        return functionKey;
    }

    function getElementWithIkaBFAttribute(path: Array<EventTarget>) {
        for (const element of path) {
            if (element instanceof HTMLElement && element.hasAttribute('ika:bf')) {
                return element
            }
        }

    }
}