import { DocumentCreateElement } from '../../common/shortcuts';
import IkaMapElements, { PostMappingOption } from './index';
import { setBind } from './setBind';

const ASIDE_CONTAINER_TAG_NAME = 'ika:aside';

export function postMappingOperations(this: IkaMapElements, mode: PostMappingOption) {
    if (![PostMappingOption.Aside, PostMappingOption.Remove].includes(mode)) return;

    switch (mode) {
        case PostMappingOption.Aside:
            if (this.parentElement?.tagName?.toLowerCase() == ASIDE_CONTAINER_TAG_NAME) return;

            let asideContainer = document.getElementsByTagName(ASIDE_CONTAINER_TAG_NAME)[0];
            if (!asideContainer) {
                asideContainer = DocumentCreateElement(ASIDE_CONTAINER_TAG_NAME)
                document.body.firstChild.before(asideContainer);
            }

            asideContainer.appendChild(this);
            break;
        case PostMappingOption.Remove:
            // Deregister bind and remove element from DOM
            setBind.bind(this)(this.getAttribute('ika:is'), true);
            this.parentNode.removeChild(this);
            break;
        default: break;
    }
}