export default class IkaA extends HTMLElement {
    constructor() {
        super();

        this.onclick = handleClick.bind(this)

        function handleClick() {
            const resourcePath = this.getAttribute('href')
            const target = document.getElementById(this.getAttribute('target'))

            if (target) {
                // TODO: Test memory management taking removeChild vs setting innerHTML methods
                // const nodesToRemove = Array.from(target.childNodes)
                // while (target.firstChild) { target.removeChild(target.lastChild) }
                target.innerHTML = `<ika-import src="${resourcePath}"></ika-import>`
                // nodesToRemove.forEach(n => console.log(n))
            }
        }
    }
}
