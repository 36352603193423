import { IkaComponent } from "../ika";
import BindingComponent from "../core/bind"
import IkaSDC from "../components/sdc";

export enum CustomEventTypes {
    ComponentCall = 'ika-component-call',
    GetTopLevelIkaME = `ika-me-seek`
}

export namespace ComponentCall {
    export enum EventType {
        RegisterBind = 'REGISTER_BIND',
        DeregisterBind = 'DEREGISTER_BIND',
        EstablishChain = 'ESTABLISH_CHAIN',
        GetComponent = 'GET_PARENT',
        InvokeFunction = 'INVOKE_FUNCTION',

    }

    export type EventDetails =
        EventDetail.DeregisterBind |
        EventDetail.RegisterBind |
        EventDetail.EstablishChain |
        EventDetail.GetComponent |
        EventDetail.InvokeFunction

    export namespace EventDetail {
        type BaseEventDetails = {
            type: EventType,
            nodeId: string,
        }

        export type EstablishChain = BaseEventDetails & {
            type: EventType.EstablishChain
            payload: { issuer: IkaSDC }
        }
        export type GetComponent = BaseEventDetails & {
            type: EventType.GetComponent,
            payload: {
                cb: (c: IkaComponent) => void,
                leap?: number,
                retry?: number
            }
        }

        export type RegisterBind = BaseEventDetails & {
            type: EventType.RegisterBind,
            payload: {
                bind: string,
                issuer: BindingComponent,
                leap?: number,
                retry?: number
            }
        }
        export type DeregisterBind = Omit<RegisterBind, 'type'> & { type: EventType.DeregisterBind }

        export type InvokeFunction = {
            type: EventType.InvokeFunction,
            payload: {
                invocationEvent: Event,
                key: string,
                leap?: number
                params?
            }
        }
    }
}

export namespace BindingEvent {
    export type ValueUpdate<T = any> = { k: string, v: T }
}