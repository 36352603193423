import { IkaDebugStyles } from "../debug";
import { ika } from "../ika";
import { BindCallback, BindCallbackArray, BindCallbackRecord } from "../types/binds";
import { BindingEvent } from "../types/events";
import { BindEvent } from "../types/debug";
import { BindRecord, BindRecords, GlobalBindPermissions } from "./reg";

export default class StateBindRegister {
    #binds: BindRecords = {}
    #host = null

    constructor(element: HTMLElement) { this.#host = element }

    listStates() {
        if (IKA_CONFIG.debugMode) {
            return this.#binds
        }
    }

    subscribeToBind(k: string, nodeId: string, cb: BindCallback) {
        if (!nodeId || !k || !cb) {
            ika.print(BindEvent.UnregisteredNodeSubscribing, k)
            return;
        }

        const newCbRecord: BindCallbackRecord = { nodeId: nodeId, cb: cb }
        if (!(k in this.#binds)) {
            // Subscribing to a global bind is allowed before it's been set a value. This defaults to a permissionless bind var.
            this.#binds[k] = { v: null, cb: [newCbRecord] }
        } else {
            const bindRecord = this.#binds[k]
            if (rejectIfNotPermissioned(bindRecord)) { return }

            bindRecord.cb.push(newCbRecord)
            cb({ k: k, v: bindRecord.v })
        }

        ika.print(BindEvent.Subscribed, nodeId, k)

        function rejectIfNotPermissioned(bindRecord: BindRecord) {
            if (bindRecord.p && !bindRecord.p.rw.includes(nodeId) && bindRecord.p.r && !bindRecord.p.r.includes(nodeId)) {
                ika.print(BindEvent.BindingPermissionDenied, nodeId, k)
                return true;
            }
        }
    }

    unsubscribeToBind(nodeId: string, k?: string) {
        if (k && !(k in this.#binds)) {
            console.warn(`"%c${k}%c" is not a registered bind key.`, IkaDebugStyles.VariableValue, 'color: unset'); return;
        }

        const scope = k ? [k] : Object.keys(this.#binds)

        scope.forEach(bind => {
            this.#binds[bind].cb = this.#binds[bind].cb.filter(entry => entry.nodeId != nodeId)
        })
    }

    clearDeregisteredNode(nodeId: string) {
        performance.mark('registry-deregister-node-from-binds-start', {
            detail: { nodeId: nodeId }
        })

        const bindEntries = Object.entries(this.#binds)
        bindEntries.forEach(([k, bind]) =>
            bind.cb = bind.cb.filter(record => record.nodeId != nodeId)
        )

        performance.measure('registry-deregister-node-from-binds-end', {
            start: 'registry-deregister-node-from-binds-start',
            detail: { nodeId: nodeId, bindsCount: bindEntries.length }
        })
    }
    getValue(k: string, nodeId?: string) {
        if (!k) { console.error(`getValue() must specify a bind key.`); return; }

        if (!this.#binds[k]) {
            console.warn(`Tried reading state "${k}", but it's not defined.`)
            return null
        }

        const p = this.#binds[k].p
        const nodeIdHasReadPermission = p && nodeId && (p.rw.includes(nodeId) || (p.r && p.r.includes(nodeId)))
        if (!p || nodeIdHasReadPermission) {
            // DESIGN DECISION?
            // This allows the change of reference types without triggering a bind update.
            // Prevent unauthorised changes to the array by using the permissioning system.
            // Otherwise, always create a new reference value to set as new value to trigger a bind update.
            return this.#binds[k].v
        } else {
            console.warn(`Node ID ${nodeId} tried reading state ${k}, but it has no permission to read.`)
        }

    }

    deleteNodeFromBindRecords(node: HTMLElement, id: string) {
        this.clearDeregisteredNode(id)
        ika.reg.deregisterNode(node, id)
    }

    setValue(k: string, v: any, nodeId?: string, p?: GlobalBindPermissions) {
        if (k in this.#binds) {
            const bindRecord = this.#binds[k]
            if (bindRecord.v === v) return;

            const hasPermission = checkIfPermissionedForRW(bindRecord)
            if (!hasPermission) return;

            Object.assign(bindRecord, { v: v })
            p && setPermissions(nodeId, p, bindRecord)

            ika.print(BindEvent.BindingValueChanged, k, this.#host, v, this.#binds[k].cb.length)
            cleanInvokeCbs.bind(this)(bindRecord.cb, { k: k, v: v })

        } else {
            this.#binds[k] = {
                v: v,
                cb: []
            }
            p && setPermissions(nodeId, p, this.#binds[k])
        }

        function cleanInvokeCbs(ar: BindCallbackArray, u: BindingEvent.ValueUpdate) {
            ar.forEach(entry => {
                const node = ika.reg.getNodeFromId(entry.nodeId) as HTMLElement
                node?.isConnected
                    ? entry.cb(u)
                    : this.deleteNodeFromBindRecords(node, entry.nodeId)
            })
        }

        function copyPermissionObject(p: GlobalBindPermissions) {
            // Permission is copied from arrays so that they cannot be changed outside IkaRegistry class methods.
            const out: GlobalBindPermissions = { rw: [] }
            if (Array.isArray(p.rw)) { out.rw = [...p.rw] }
            if (Array.isArray(p.r)) { out.r = [...p.r] }

            return out
        }

        function checkIfPermissionedForRW(bindRecord: BindRecords[number]) {
            if (bindRecord.p && !nodeId) {
                console.error(`Error setting bind value "${k}": a node ID must be provided as the binding key is permissioned.`);
                return false;
            }

            if (bindRecord.p && !bindRecord.p.rw.includes(nodeId)) {
                console.warn(`"%c${k}%c" is already registered as a permissioned bind, and this node ID is not permissioned for Read/Write.
                If you meant to register a bind for the first time, nodes with Read/Write permission must unregister it first.`,
                    IkaDebugStyles.VariableValue, 'color: unset');
                return false;
            }
            return true;
        }

        function setPermissions(nodeId: string, p: GlobalBindPermissions, bindRecord: BindRecords[number]) {
            if (!nodeId) {
                console.warn(`Permissions on bind key "%c${k}%c" is not set, as no node ID was provided.`, IkaDebugStyles.VariableValue, 'color: unset')
            } else {
                if (bindRecord.p && !bindRecord.p.rw.includes(nodeId)) {
                    console.warn(`Setting permissions on bind key "%c${k}%c" is denied. The provided node ID does not have RW permission.`, IkaDebugStyles.VariableValue, 'color: unset')
                    return;
                }
                // Automatically giving the node setting permissions RW privilege.
                const permissions: GlobalBindPermissions = copyPermissionObject(p)
                permissions.rw.push(nodeId)
                Object.assign(bindRecord, { p: permissions })
            }
        }
    }
}