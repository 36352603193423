import { ika } from '../ika'
import { BufferObject, BufferUpdate, UpdateType } from '../types/buffer'
import { BufferEvent } from '../types/debug'

export class IkaBuffer {
    #buffer: BufferObject = {}
    constructor() { }

    debug() {
        return {
            buffer: this.#buffer
        }
    }

    push(update: BufferUpdate) {
        if (!['string', 'number', 'boolean'].includes(typeof update.v)) {
            ika.print(BufferEvent.UnexpectedData, update)
            return
        }
        const buf = this.#buffer

        function checkAndInit(o, k: string | number, i) {
            if (!o[k]) { o[k] = i }
        }
        checkAndInit(buf, update.n, {})
        checkAndInit(buf[update.n], update.type, {})
        checkAndInit(buf[update.n][update.type], update.k, [])

        buf[update.n][update.type][update.k].push(update.v)
    }



    getBuffer() {
        optimiseBuffer()
        return cloneBuffer(this.#buffer)

        function cloneBuffer(cloneFrom: BufferObject) {
            const buffer: BufferObject = {}
            Object.entries(cloneFrom).forEach(([nodeId, typeGrouping]) => {
                buffer[nodeId] = {} as { [type in UpdateType]: {} }
                Object.entries(typeGrouping).forEach(([type, keyArr]) => {
                    buffer[nodeId][type] = {};
                    Object.entries(keyArr).forEach(([k, v]) => {
                        buffer[nodeId][type][k] = [...v]
                    })
                })
            })
            return buffer
        }

        function optimiseBuffer() {

        }
    }

    resetBuffer() { this.#buffer = {} }
}



export function processBuffer() {
    Object.entries(ika.buffer.getBuffer()).forEach(([nodeId, updates]) => {
        const target = ika.reg.getNodeFromId(nodeId)

        // This passed Buffer is without any optimisation - should receive val as a single value only. 
        UpdateType.PROP in updates
            && Object.entries(updates[UpdateType.PROP]).forEach(([key, val]) => {
                target[key] = val.pop()
            })
        UpdateType.ATTR in updates && target.nodeType == Node.ELEMENT_NODE
            && Object.entries(updates[UpdateType.ATTR]).forEach(([key, val]) => {
                (target as Element).setAttribute(key, val.pop().toString())
            })

    })

    ika.buffer.resetBuffer()
}
