import { IkaDebugStyles } from "."
import { BindEvent, ComponentEvent, DebugFilter, IkaStatus, NodeEvent } from "../types/debug"
import DEBUG_MESSAGES_EN_GB from './locale/en-gb'

type DebugMessageFunction = (...params: any[]) => DebugMessageReturn
type DebugMessageReturn = { s: string, css?: Array<string>, o?: Array<any>, mode?: 'debug' | 'info' | 'warn' | 'error' }

export function buildIndex<T extends { [key in DebugFilter]: DebugMessageFunction }>(i: T) { return i }

type LocaleCodes = 'en-gb'
type DebugMessageDictionary = { [locale in LocaleCodes]: typeof DEBUG_MESSAGES_EN_GB }

const DEBUG_MESSAGES: DebugMessageDictionary = {
    'en-gb': DEBUG_MESSAGES_EN_GB
}

export function printDebugMessage<C extends DebugFilter>(code: C, ...params: Parameters<typeof DEBUG_MESSAGES_EN_GB[C]>) {
    const locale = IKA_CONFIG.locale || 'en-gb'
    const colours = {
        debug: '#6aaff8',
        warn: 'orange',
        error: 'red'
    }

    if (IKA_CONFIG.verbose) {
        if (IKA_CONFIG?.suppressMessages?.includes(code) || (IKA_CONFIG?.filterMessages && !IKA_CONFIG?.filterMessages?.includes(code))) return;

        const { s, css, o, mode } = DEBUG_MESSAGES[locale][code].apply(null, params)

        const args = [`color: ${colours[mode || 'debug']}; font-weight: 600`, 'color: unset; font-weight: unset']

        for (const match of s.matchAll(/(%[co])/g)) {
            const target = match[0] == '%c' ? css : o
            if (!Array.isArray(target)) return
            args.push(target[0]);
            target.shift()
        }

        console[mode || 'debug'](`%c<${mode || 'debug'}>%c ` + s, ...args)
    }
}

